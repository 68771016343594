import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const years = [2023, 2022, 2021]

export default function PositionLetters() {
  return (
    <Layout>
      <SEO title="Position Letters" />
      <h1>Position Letters</h1>

      <ul className="mt-3 ml-5 list-disc list-outside">
        {years.map(year => {
          return (
            <li>
              <Link to={`/${year}/position-letters`}>{year}</Link>
            </li>
          )
        })}
      </ul>
    </Layout>
  )
}
